<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        :fill-color="color"
        @click="$emit('click')"
    >
        <line class="a" x1="20.25" y1="12" x2="23.25" y2="12" />

        <line class="a" x1="0.75" y1="12" x2="3.75" y2="12" />

        <line class="a" x1="12" y1="0.75" x2="12" y2="3.75" />

        <line class="a" x1="12" y1="20.25" x2="12" y2="23.25" />

        <path
            class="a"
            d="M18.75,9H15V5.25a1.5,1.5,0,0,0-1.5-1.5h-3A1.5,1.5,0,0,0,9,5.25V9H5.25a1.5,1.5,0,0,0-1.5,1.5v3A1.5,1.5,0,0,0,5.25,15H9v3.75a1.5,1.5,0,0,0,1.5,1.5h3a1.5,1.5,0,0,0,1.5-1.5V15h3.75a1.5,1.5,0,0,0,1.5-1.5v-3A1.5,1.5,0,0,0,18.75,9Z"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'ConnectorIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
