<template>
    <div class="l-stack l-gap-2">
        <h2 class="l-inline l-spread l-center-v">
            {{ title }}

            <AddIcon
                width="24"
                height="24"
                class="clickable"
                @click="openForm()"
            />
        </h2>

        <transition name="settings-form">
            <component
                :is="formComponent"
                v-if="editMode && editItem === null"
                class="settings-form"
                :additional-data="additionalData"
                @close="handleFormClose"
            />
        </transition>

        <template v-for="(item, i) in items">
            <component
                :is="formComponent"
                v-if="editMode && editItem === i"
                :key="i"
                class="settings-form"
                :initial-value="items[editItem]"
                :additional-data="additionalData"
                @close="handleFormClose"
            />

            <NotificationSettingsItem
                v-else
                :key="i"
                :title="getTitle(item)"
                :description="getDescription(item)"
                class="clickable"
                @click="openForm(i)"
            >
                <template #icon>
                    <component :is="valueIcon" width="24" height="24" />
                </template>

                <template #iconRight>
                    <a
                        class="clickable"
                        @click.stop="$refs.contextMenu.showMenu($event, i)"
                    >
                        <MenuDotsHIcon width="24" height="24" />
                    </a>
                </template>
            </NotificationSettingsItem>
        </template>

        <NotificationSettingsItem
            v-if="
                (!items.length === 0 || Object.keys(items).length === 0) &&
                    !editMode
            "
            :title="placeholder"
            :description="placeholderHint"
            empty
            class="clickable"
            @click="openForm()"
        >
            <template #icon>
                <component :is="placeholderIcon" width="24" height="24" />
            </template>
        </NotificationSettingsItem>

        <ContextMenu
            ref="contextMenu"
            :element-id="`notificationEditFragmentContextMenu${_uid}`"
            class="no-margin"
            :options="contextMenuOptions"
            @option-clicked="handleContextMenuClick"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import ContextMenu from 'vue-simple-context-menu'

import { measurementHelper } from '@/utils'
import AddIcon from '../icons/AddIcon'
import AlarmBellIcon from '../icons/AlarmBellIcon'
import AlertDiamondIcon from '../icons/AlertDiamondIcon'
import ConnectorIcon from '../icons/ConnectorIcon'
import LocationTargetIcon from '../icons/LocationTargetIcon'
import MenuDotsHIcon from '../icons/MenuDotsHIcon.vue'
import MonitorHeartRateIcon from '../icons/MonitorHeartRateIcon'
import NotificationActionForm from './NotificationActionForm'
import NotificationConditionForm from './NotificationConditionForm'
import NotificationSettingsItem from './NotificationSettingsItem'
import NotificationTargetForm from './NotificationTargetForm'
import NotificationWebhooksForm from './NotificationWebhooksForm'

const formComponentMap = {
    action: NotificationActionForm,
    condition: NotificationConditionForm,
    target: NotificationTargetForm,
    webhooks: NotificationWebhooksForm,
}

const placeholderIcontMap = {
    action: AlarmBellIcon,
    condition: AlertDiamondIcon,
    target: LocationTargetIcon,
    webhooks: ConnectorIcon,
}

const valueIcontMap = {
    action: AlarmBellIcon,
    condition: MonitorHeartRateIcon,
    target: LocationTargetIcon,
    webhooks: ConnectorIcon,
}

export default {
    name: 'NotificationEditFragment',
    components: {
        AddIcon,
        ContextMenu,
        MenuDotsHIcon,
        NotificationSettingsItem,
    },
    props: {
        additionalData: {
            type: Object,
            default: () => {},
        },
        placeholder: {
            type: String,
            required: true,
        },
        placeholderHint: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            required: true,
            validator(value) {
                return ['action', 'condition', 'target', 'webhooks'].includes(
                    value
                )
            },
        },
        value: {
            type: [Array, Object],
            default: undefined,
        },
    },
    data() {
        return {
            contextMenuOptions: [
                { action: 'edit', name: this.$t('editItem') },
                {
                    action: 'delete',
                    name: this.$t('deleteItem'),
                    class: 'danger',
                },
            ],
            editMode: false,
            editItem: null,
            items: [],
            operatorTransalations: {
                '<': this.$t('lt'),
                '=': this.$t('eq'),
                '>': this.$t('gt'),
            },
            units: measurementHelper.units,
            timeOptions: {
                h: 60 * 60,
                min: 60,
            },
        }
    },
    computed: {
        ...mapState('location', ['locations']),
        ...mapState('tracker', ['trackers']),
        formComponent() {
            return formComponentMap[this.type]
        },
        placeholderIcon() {
            return placeholderIcontMap[this.type]
        },
        valueIcon() {
            return valueIcontMap[this.type]
        },
    },
    watch: {
        items() {
            this.$emit('input', this.items)
        },
        value: {
            immediate: true,
            handler() {
                this.items = this.value || (this.type === 'target' ? {} : [])
            },
        },
    },
    methods: {
        getTitle(item) {
            switch (this.type) {
                case 'action':
                    return this.$t('action-' + item.type)
                case 'condition':
                    return item.type === 'geofence' ||
                        item.type === 'offline' ||
                        item.type === 'online' ||
                        item.type === 'state' ||
                        (item.type === 'regex' &&
                            item.value !== 'battery_status')
                        ? this.$t(`shared.measurements.${item.type}`)
                        : item.type === 'maintenance'
                        ? this.$t('maintenance')
                        : item.type === 'weekday_schedule'
                        ? this.$t('weekdaySchedule')
                        : this.$root.$te(`shared.measurements.${item.value}`)
                        ? this.$t(`shared.measurements.${item.value}`)
                        : item.value
                case 'target':
                    return this.$t('target-' + item.type, [
                        this.$t('shared.assets'),
                    ])
                case 'webhooks':
                    return item.method
            }
        },
        getDescription(item) {
            let amount
            switch (this.type) {
                case 'action':
                    if (item.target) {
                        return item.target
                    } else if (item.type === 'EMAIL_PRIMARY') {
                        return this.additionalData?.userEmail || ''
                    } else if (item.type === 'SMS_PRIMARY') {
                        return this.additionalData?.userSms || ''
                    }
                    return 'unknown'
                case 'condition':
                    switch (item.type) {
                        case 'geofence':
                            return `${this.$t(item.position)} ${
                                this.locations.find(
                                    location => location.id === item.locationId
                                )?.name
                            }`
                        case 'maintenance':
                            return this.$t('maintenanceNeeded')
                        case 'offline':
                        case 'online':
                            amount =
                                item.seconds / this.timeOptions[item.user_unit]
                            return this.$t('OfflineFor', {
                                amount,
                                unit: item.user_unit,
                            })
                        case 'regex':
                            return item.value === 'battery_status'
                                ? this.$t(
                                      'shared.measurementOptions.battery_status.' +
                                          item.regex
                                  )
                                : this.$t('regexMatches', [
                                      item.value,
                                      item.regex,
                                  ])
                        case 'state':
                            return `${this.$t(item.value)} ${this.$t(
                                item.state ? 'isOn' : 'isOff'
                            )}`
                        case 'weekday_schedule':
                            return item.weekdays
                                .map(
                                    ({ weekday, start, end }) =>
                                        `${this.$t(
                                            `shared.weekdays.${weekday}`
                                        )} ${start} - ${end}`
                                )
                                .join(', ')
                        default:
                            return this.getDescriptionConditionThreshold(item)
                    }
                case 'target':
                    return item.type === 'all'
                        ? this.$tc('assets', this.trackers.length)
                        : this.$tc(item.type, this.items[item.type].length)
                case 'webhooks':
                    return item.url.replace(/.*\/\/|\/.*/g, '')
            }
        },
        getDescriptionConditionThreshold(item) {
            switch (item.value) {
                case 'button_mode':
                    return this.$t(
                        `shared.measurementOptions.${item.value}.${item.threshold}`
                    )
                case 'dtc_codes':
                    return this.$t('dtcCodesAvailable')
                case 'ev_charging_state':
                    return this.$t(item.threshold ? 'isOn' : 'isOff')
            }
            const operator = this.operatorTransalations[item.operator] || ''
            const unit = this.units[item.value] || ''
            const value =
                item.regex ||
                (item.value === 'fill_level'
                    ? item.threshold * 100
                    : item.threshold)

            return `${operator} ${value} ${unit}`
        },
        openForm(itemIndex = null) {
            this.editMode = true
            this.editItem = itemIndex
        },
        closeForm() {
            this.editMode = false
            this.editItem = null
        },
        handleFormClose(value) {
            if (!value) {
                this.closeForm()
                return
            }

            if (
                this.type === 'action' &&
                ((value.type === 'EMAIL_PRIMARY' &&
                    this.items.some(item => item.type === 'EMAIL_PRIMARY')) ||
                    (value.type === 'SMS_PRIMARY' &&
                        this.items.some(item => item.type === 'SMS_PRIMARY')))
            ) {
                if (this.editItem !== null) {
                    this.items.splice(this.editItem, 1)
                }
            } else if (this.type === 'target') {
                this.items = {
                    ...this.items,
                    [value.type === 'all' ? 'assets' : value.type]: value,
                }
            } else if (this.editItem === null) {
                this.items.push(value)
            } else {
                this.items[this.editItem] = value
            }

            this.closeForm()
        },
        handleContextMenuClick({ option, item }) {
            switch (option.action) {
                case 'delete':
                    if (this.type === 'target') {
                        delete this.items[item]
                        this.items = { ...this.items }
                    } else {
                        this.items.splice(item, 1)
                    }
                    if (this.editItem === item) {
                        this.closeForm()
                    }
                    break
                case 'edit':
                    this.openForm(item)
                    break
            }
        },
    },
}
</script>

<i18n>
{
    "en": {
        "action-EMAIL": "E-Mail to",
        "action-EMAIL_PRIMARY": "E-Mail to me",
        "action-SMS": "SMS to",
        "action-SMS_PRIMARY": "SMS to me",
        "assets": "{n} asset | {n} assets",
        "d1": "Channel 1",
        "d2": "Channel 2",
        "d3": "Channel 3",
        "d4": "Channel 4",
        "d5": "Channel 5",
        "d6": "Channel 6",
        "deleteItem": "Delete",
        "dtcCodesAvailable": "DTC Code available",
        "editItem": "Edit",
        "eq": "Equals",
        "gt": "Greater than",
        "inside":"Inside",
        "isOff": "is Off",
        "isOn": "is On",
        "locations": "{n} location | {n} locations",
        "lt": "Less than",
        "maintenance": "Maintenance",
        "maintenanceNeeded": "Maintenance is needed",
        "outside": "Outside",
        "primary": "Primary",
        "regexMatches": "{0} matches {1}",
        "target-all": "All {0}",
        "target-assets": "Individual {0}",
        "target-locations": "Location",
        "target-types": "{0} Types",
        "types": "{n} type | {n} types",
        "weekdaySchedule": "Weekday schedule",
        "OfflineFor": "for {amount} {unit}"
    },
    "de": {
        "action-EMAIL": "E-Mail an",
        "action-EMAIL_PRIMARY": "E-Mail an mich",
        "action-SMS": "SMS an",
        "action-SMS_PRIMARY": "SMS an mich",
        "assets": "{n} Asset | {n} Assets",
        "d1": "Kanal 1",
        "d2": "Kanal 2",
        "d3": "Kanal 3",
        "d4": "Kanal 4",
        "d5": "Kanal 5",
        "d6": "Kanal 6",
        "deleteItem": "Löschen",
        "dtcCodesAvailable": "DTC Code vorhanden",
        "editItem": "Bearbeiten",
        "eq": "Gleich",
        "gt": "Grösser als",
        "inside":"Innerhalb",
        "isOff": "aus",
        "isOn": "ein",
        "locations": "{n} Standort | {n} Standorte",
        "lt": "Kleiner als",
        "maintenance": "Wartung",
        "maintenanceNeeded": "Wartung ist erforderlich",
        "outside": "Ausserhalb",
        "primary": "Primary",
        "regexMatches": "{0} matches {1}",
        "target-all": "Alle {0}",
        "target-assets": "Individuelle {0}",
        "target-locations": "Standort",
        "target-types": "Typ",
        "types": "{n} Typ | {n} Typen",
        "weekdaySchedule": "Zeitplan",
        "OfflineFor": "für {amount} {unit}"
    },
    "fr": {
        "action-EMAIL": "E-mail à",
        "action-EMAIL_PRIMARY": "E-mail à moi-même",
        "action-SMS": "SMS à",
        "action-SMS_PRIMARY": "SMS à moi-même",
        "assets": "{n} asset | {n} assets",
        "d1": "Canal 1",
        "d2": "Canal 2",
        "d3": "Canal 3",
        "d4": "Canal 4",
        "d5": "Canal 5",
        "d6": "Canal 6",
        "deleteItem": "Supprimer",
        "dtcCodesAvailable": "DTC Code disponible",
        "editItem": "Editer",
        "eq": "est égal à",
        "gt": "plus grand que",
        "inside":"InnerhalbA l'intérieur de",
        "isOff": "est Off",
        "isOn": "est On",
        "locations": "{n} Emplacement | {n} Emplacements",
        "lt": "plus petit que",
        "maintenance": "Maintenance",
        "maintenanceNeeded": "Une maintenance est nécessaire",
        "outside": "A l'extérieur de",
        "primary": "Primaire",
        "regexMatches": "{0} matches {1}",
        "target-all": "Tout {0}",
        "target-assets": "Individuel {0}",
        "target-locations": "Emplacement",
        "target-types": "{0} Types",
        "types": "{n} Type | {n} Types",
        "weekdaySchedule": "Horaire",
        "OfflineFor": "pour {amount} {unit}"
    },
    "it": {
        "action-EMAIL": "E-Mail a",
        "action-EMAIL_PRIMARY": "E-mail a me",
        "action-SMS": "SMS a",
        "action-SMS_PRIMARY": "SMS a me",
        "assets": "{n} asset | {n} assets",
        "d1": "Channel 1",
        "d2": "Channel 2",
        "d3": "Channel 3",
        "d4": "Channel 4",
        "d5": "Channel 5",
        "d6": "Channel 6",
        "deleteItem": "Cancellare",
        "dtcCodesAvailable": "DTC Code disponibile",
        "editItem": "Condizione",
        "eq": "è uguale a",
        "gt": "Maggiore di",
        "inside":"All'interno di",
        "isOff": "è off",
        "isOn": "è on",
        "locations": "{n} luogho | {n} luoghi",
        "lt": "Meno di",
        "maintenance": "Manutenzione",
        "maintenanceNeeded": "La manutenzione è necessaria",
        "outside": "All'esterno di",
        "primary": "Primary",
        "regexMatches": "{0} matches {1}",
        "target-all": "Tutti {0}",
        "target-assets": "{0} individuale",
        "target-locations": "Luogho",
        "target-types": "{0} Typo",
        "types": "{n} tipo | {n} tipi",
        "weekdaySchedule": "Orario",
        "OfflineFor": "per {amount} {unit}"
    }
}
</i18n>

<style lang="scss" scoped>
h2 {
    font-weight: 700;
    font-size: 20px;
    color: $color-text-new;
}

h2,
a {
    svg {
        color: $color-gray-dark-new;
    }
}

.no-margin {
    margin: 0;
}

.settings-form {
    width: 100%;
    padding: 24px 16px;
    background-color: #fff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    font-size: 14px;
    color: $color-text-new;
    transition: all 0.2s;

    &-enter,
    &-leave-to {
        opacity: 0;
        transform: translateY(-32px) scale(0.95);
    }
}
</style>
