<template>
    <div>
        <div class="form-label l-inline l-spread l-center-v">
            <span>{{ label }}</span>

            <IconButton @click.prevent="handleAdd">
                <AddIcon width="14" height="14" />
            </IconButton>
        </div>

        <div class="l-stack l-gap-1">
            <div
                v-for="(row, i) in valueInternal"
                :key="i"
                class="l-inline l-gap-1 l-center-v"
            >
                <BaseInput
                    v-if="type === 'map'"
                    v-model="row[0]"
                    :placeholder="keyPlaceholder"
                />

                <BaseInput
                    v-model="row[1]"
                    :placeholder="valuePlaceholder"
                    :class="{
                        'l-full-width': type === 'list',
                    }"
                />

                <IconButton
                    v-if="valueInternal.length"
                    :disabled="valueInternal.length === 1 && !row[0] && !row[1]"
                    @click.prevent="handleRemove(i)"
                >
                    <RemoveIcon width="14" height="14" />
                </IconButton>
            </div>
        </div>
    </div>
</template>

<script>
import AddIcon from '../icons/AddIcon'
import BaseInput from './BaseInput'
import IconButton from '../IconButton'
import RemoveIcon from '../icons/RemoveIcon'

export default {
    name: 'MultiInput',
    components: {
        AddIcon,
        BaseInput,
        IconButton,
        RemoveIcon,
    },
    props: {
        type: {
            type: String,
            default: 'list',
            validator(value) {
                return ['list', 'map'].includes(value)
            },
        },
        value: {
            type: Array,
            default: () => [],
        },
        label: {
            type: String,
            default: '',
        },
        keyPlaceholder: {
            type: String,
            default: '',
        },
        valuePlaceholder: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            valueInternal: [['', '']],
        }
    },
    watch: {
        valueInternal() {
            const value = this.valueInternal.filter(
                ([key, value]) => key && value
            )

            if (this.type === 'list') {
                this.valueInternal = this.valueInternal.map(
                    ([, value]) => value
                )
            }

            this.$emit('input', value)
        },
    },
    mounted() {
        switch (this.type) {
            case 'list':
                this.valueInternal = this.value
                    .filter(item => typeof item === 'string')
                    .map(item => ['', item])
                break

            case 'map':
                this.valueInternal = this.value.filter(
                    item =>
                        Array.isArray(item) &&
                        typeof item[0] === 'string' &&
                        typeof item[1] === 'string'
                )
                if (!this.valueInternal.length) {
                    this.valueInternal = [['', '']]
                }
                break
        }
    },
    methods: {
        handleAdd() {
            this.valueInternal.push(['', ''])
        },
        handleRemove(index) {
            if (this.valueInternal.length === 1) {
                this.valueInternal = [['', '']]
            } else {
                this.valueInternal = [
                    ...this.valueInternal.slice(0, index),
                    ...this.valueInternal.slice(index + 1),
                ]
            }
        },
    },
}
</script>
