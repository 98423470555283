<template>
    <form class="l-stack l-gap-3" @submit.prevent="handleSubmit">
        <div class="l-stack l-gap-2">
            <BaseInput
                v-model="description"
                :placeholder="$t('descriptionPlaceholder')"
                textarea
            />

            <BaseMultiselect
                v-model="method"
                :options="methodOptions"
                :placeholder="$t('methodPlaceholder')"
            />

            <div>
                <p v-show="$v.url.$dirty && $v.url.$invalid" class="form-error">
                    {{ $t('invalidUrl') }}
                </p>

                <BaseInput
                    v-model="$v.url.$model"
                    :placeholder="$t('urlPlaceholder')"
                />
            </div>

            <MultiInput
                v-model="headers"
                type="map"
                :label="$t('headersLabel')"
                :key-placeholder="$t('headerNamePlaceholder')"
                :value-placeholder="$t('headerValuePlaceholder')"
            />

            <BaseInput
                v-model="payload"
                :placeholder="$t('payloadPlaceholder')"
                textarea
            />
        </div>

        <div class="l-inline l-spread l-center-v">
            <span class="clickable" @click="$emit('close')">
                {{ $t('shared.cancel') }}
            </span>

            <base-button size="small" type="submit" :disabled="isDisabled">
                {{ $t('shared.save') }}
            </base-button>
        </div>
    </form>
</template>

<script>
import { url } from 'vuelidate/lib/validators'
import BaseButton from './BaseButton'
import BaseInput from './BaseInput'
import BaseMultiselect from './BaseMultiselect'
import MultiInput from './MultiInput'

export default {
    name: 'NotificationWebhooksForm',
    components: {
        BaseButton,
        BaseInput,
        BaseMultiselect,
        MultiInput,
    },
    props: {
        initialValue: {
            type: Object,
            default: undefined,
        },
    },
    data() {
        return {
            headers: [],
            method: null,
            methodOptions: ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'],
            payload: null,
            description: null,
            url: null,
        }
    },
    validations() {
        return {
            url: {
                url,
            },
        }
    },
    computed: {
        isDisabled() {
            return (
                !this.method ||
                !this.url ||
                this.$v.url.$error ||
                !this.description
            )
        },
    },
    watch: {
        initialValue: {
            immediate: true,
            handler() {
                this.setInitialValue()
            },
        },
    },
    methods: {
        setInitialValue() {
            if (this.initialValue) {
                this.method = this.initialValue.method
                this.url = this.initialValue.url
                this.headers = Object.entries(this.initialValue.headers)
                this.payload = this.initialValue.payload
                this.description = this.initialValue.description
            } else {
                this.headers = Object.entries({
                    'Content-type': 'application/json',
                    '': '',
                })
            }
        },
        handleSubmit() {
            const value = {
                id: this.initialValue?.id,
                method: this.method,
                url: this.url,
                headers: Object.fromEntries(this.headers),
                payload: this.payload,
                description: this.description,
            }
            this.$emit('close', value)
        },
    },
}
</script>

<i18n>
{
    "en": {
        "descriptionPlaceholder": "Description",
        "headerNamePlaceholder": "Name",
        "headerValuePlaceholder": "Value",
        "headersLabel": "HTTP headers",
        "invalidUrl": "Invalid URL",
        "methodPlaceholder": "HTTP request method",
        "payloadPlaceholder": "Request payload",
        "urlPlaceholder": "URL"
    },
    "de": {
        "descriptionPlaceholder": "Beschreibung",
        "headerNamePlaceholder": "Name",
        "headerValuePlaceholder": "Wert",
        "headersLabel": "HTTP Headers",
        "invalidUrl": "Ungültige URL",
        "methodPlaceholder": "HTTP Request Methode",
        "payloadPlaceholder": "Request Payload",
        "urlPlaceholder": "URL"
    },
    "fr": {
        "descriptionPlaceholder": "Description",
        "headerNamePlaceholder": "Nom",
        "headerValuePlaceholder": "Valeur",
        "headersLabel": "HTTP Headers",
        "invalidUrl": "URL non valide",
        "methodPlaceholder": "Méthode de requête HTTP",
        "payloadPlaceholder": "Payload de la requête",
        "urlPlaceholder": "URL"
    },
    "it": {
        "descriptionPlaceholder": "Descrizione",
        "headerNamePlaceholder": "Nome",
        "headerValuePlaceholder": "Valore",
        "headersLabel": "HTTP headers",
        "invalidUrl": "URL non valido",
        "methodPlaceholder": "HTTP request metodo",
        "payloadPlaceholder": "Request payload",
        "urlPlaceholder": "URL"
    }
}
</i18n>
