<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <circle class="a" cx="3.375" cy="12.023" r="2.625" />

        <circle class="a" cx="20.625" cy="12.023" r="2.625" />

        <circle class="a" cx="12" cy="12.023" r="2.625" />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'MenuDotsHIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
