<template>
    <form class="l-stack l-gap-3" @submit.prevent="handleSubmit">
        <div class="l-stack l-gap-2">
            <BaseMultiselect
                v-model="type"
                :options="typeOptions"
                :custom-label="option => $t(option, [$t('shared.assets')])"
                :placeholder="$t('typePlaceholder')"
                @input="value = []"
            />

            <BaseMultiselect
                v-if="type && type !== 'all'"
                v-model="value"
                :options="valueOptions"
                track-by="id"
                :custom-label="getValueOptionLabel"
                :placeholder="$t(type, [$t('shared.assets')])"
                multiple
            />
        </div>

        <div class="l-inline l-spread l-center-v">
            <span class="clickable" @click="$emit('close')">
                {{ $t('shared.cancel') }}
            </span>

            <BaseButton
                size="small"
                type="submit"
                :disabled="!type || (type !== 'all' && !value.length)"
            >
                {{ $t('shared.save') }}
            </BaseButton>
        </div>
    </form>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import BaseButton from './BaseButton'
import BaseMultiselect from './BaseMultiselect'

export default {
    name: 'NotificationTargetForm',
    components: {
        BaseButton,
        BaseMultiselect,
    },
    props: {
        initialValue: {
            type: Array,
            default: undefined,
        },
    },
    data() {
        return {
            type: null,
            typeOptions: ['all', 'types', 'assets', 'locations'],
            value: [],
        }
    },
    computed: {
        ...mapState('tracker', ['assetTypes', 'trackers']),
        ...mapGetters('location', ['locationsSortedByName']),
        idKey() {
            return this.type === 'assets' ? 'asset' : 'id'
        },
        valueOptions() {
            switch (this.type) {
                case 'assets':
                    return this.trackers
                case 'locations':
                    return this.locationsSortedByName
                case 'types':
                    return this.assetTypes
                default:
                    return []
            }
        },
    },
    watch: {
        initialValue: {
            immediate: true,
            handler() {
                this.setInitialValue()
            },
        },
    },
    methods: {
        setInitialValue() {
            this.type = this.initialValue?.type
            this.value = this.initialValue || []

            if (this.type !== 'all' && this.value.length) {
                this.value = this.value.map(item =>
                    this.valueOptions.find(
                        option => option[this.idKey] === item
                    )
                )
            }
        },
        getValueOptionLabel(option) {
            return this.type === 'assets'
                ? `${option.asset_details.name} (${option.identifier})`
                : option.name
        },
        handleSubmit() {
            const value = this.value.map(item => item[this.idKey])
            value.type = this.type
            this.$emit('close', value)
        },
    },
}
</script>

<i18n>
{
    "en": {
        "all": "All {0}",
        "assets": "Individual {0}",
        "locations": "Location",
        "typePlaceholder": "Notification target",
        "types": "{0} types"
    },
    "de": {
        "all": "Alle {0}",
        "assets": "Individuelle {0}",
        "locations": "Standort",
        "typePlaceholder": "Anwenden auf",
        "types": "{0} Typ"
    },
    "fr": {
        "all": "Tout {0}",
        "assets": "Individuel {0}",
        "locations": "Emplacement",
        "typePlaceholder": "Appliquer à",
        "types": "{0} types"
    },
    "it": {
        "all": "Alle {0}",
        "assets": "Individuelle {0}",
        "locations": "Standort",
        "typePlaceholder": "Applicare a",
        "types": "{0} Typ"
    }
}
</i18n>
