<template>
    <div class="rule-edit-view">
        <BaseHeader :title="$t(currentId ? 'titleEdit' : 'titleCreate')" />

        <div v-if="isLoading" class="rule-edit-view__container">
            <VSpinner size="large" :line-fg-color="spinnerColor" :speed="1" />
        </div>

        <div v-else class="l-stack l-gap-4 rule-edit-view__container">
            <div class="l-stack l-gap-2">
                <h2>{{ $t('name') }}</h2>

                <BaseInput
                    v-model="name"
                    :placeholder="$t('namePlaceholder')"
                    block
                />
            </div>

            <div class="l-stack l-gap-2">
                <h2>{{ $t('subject') }}</h2>

                <BaseInput
                    v-model="subject"
                    :placeholder="$t('subjectPlaceholder')"
                    block
                />
            </div>

            <div class="l-stack l-gap-2" :class="{ large: isTextExpanded }">
                <h2>{{ $t('text') }}</h2>

                <RichTextInput
                    v-model="text"
                    :placeholder="$t('textPlaceholder')"
                    block
                    expand
                    vars
                    @expand="isTextExpanded = !isTextExpanded"
                />
            </div>

            <NotificationEditFragment
                v-model="conditions"
                type="condition"
                :title="$t('condition')"
                :placeholder="$t('conditionPlaceholder')"
                :placeholder-hint="$t('conditionPlaceholderHint')"
            />

            <NotificationEditFragment
                v-model="actions"
                type="action"
                :title="$t('action')"
                :placeholder="$t('actionPlaceholder')"
                :placeholder-hint="$t('actionPlaceholderHint')"
                :additional-data="{ userEmail, userSms }"
            />

            <NotificationEditFragment
                v-model="targets"
                type="target"
                :title="$t('target')"
                :placeholder="$t('targetPlaceholder')"
                :placeholder-hint="
                    $t('targetPlaceholderHint', [$t('shared.assets')])
                "
            />

            <NotificationEditFragment
                v-if="hasWebhookAccess"
                v-model="webhooks"
                type="webhooks"
                :title="$t('webhooks')"
                :placeholder="$t('webhooksPlaceholder')"
                :placeholder-hint="$t('webhooksPlaceholderHint')"
            />

            <div v-if="hasAdvancedRuleAccess" class="l-stack l-gap-2">
                <h2>{{ $t('advancedsettings') }}</h2>

                <BaseCard
                    class="l-gap-2 l-stack rule-edit-view__admin-settings"
                    padded
                >
                    <div class="l-inline l-gap-2 l-center-v l-spread">
                        <label>{{ $t('triggerMode') }}</label>

                        <BaseMultiselect
                            v-model="triggerMode"
                            class="l-half-width"
                            :options="triggerModeOptions"
                            :custom-label="option => $t(option)"
                        />
                    </div>

                    <div class="l-inline l-gap-2 l-center-v l-spread">
                        <label>{{ $t('alertLevel') }}</label>

                        <BaseMultiselect
                            v-model="alertLevel"
                            class="l-half-width"
                            :options="alertLevelOptions"
                            :custom-label="option => $t(option)"
                        />
                    </div>
                </BaseCard>
            </div>

            <div v-if="isSuperuser" class="l-stack l-gap-2">
                <h2>{{ $t('adminsettings') }}</h2>

                <BaseCard
                    class="l-gap-2 l-stack rule-edit-view__admin-settings"
                    padded
                >
                    <div class="l-inline l-gap-2 l-center-v">
                        <ToggleButton
                            v-model="adminRule"
                            :width="40"
                            :height="24"
                            :speed="150"
                            css-colors
                            class="redesigned"
                            sync
                        />

                        <label>{{ $t('adminrule') }}</label>
                    </div>

                    <div class="l-inline l-gap-2 l-center-v">
                        <ToggleButton
                            v-model="delayIsCustom"
                            :width="40"
                            :height="24"
                            :speed="150"
                            css-colors
                            class="redesigned"
                            sync
                        />

                        <label>{{ $t('delay') }}</label>

                        <BaseInput
                            v-show="delayIsCustom"
                            v-model="delay"
                            :placeholder="$t('value')"
                            type="number"
                            class="l-full-width"
                        >
                            <template #postfix>
                                {{ $t('time-h') }}
                            </template>
                        </BaseInput>
                    </div>

                    <div class="l-inline l-gap-2 l-center-v">
                        <ToggleButton
                            v-model="ownerIsCustom"
                            :width="40"
                            :height="24"
                            :speed="150"
                            css-colors
                            class="redesigned"
                            sync
                            @input="owner = null"
                        />

                        <label>{{ $t('owner') }}</label>

                        <BaseMultiselect
                            v-if="ownerIsCustom"
                            v-model="owner"
                            :placeholder="$t('ownerPlaceholder')"
                            :options="usersSortedByUsername"
                            label="username"
                        />
                    </div>
                </BaseCard>
            </div>

            <div class="l-stack l-gap-2">
                <BaseButton :disabled="!canSubmit" @click="handleSubmit">
                    {{ $t('shared.save') }}
                </BaseButton>

                <div class="l-stack l-gap-1 rule-edit-view__validation-hints">
                    <p :class="{ active: !name.length }">
                        <CheckCircleIcon v-if="name.length" />
                        <RemoveCircleIcon v-else />
                        {{ $t('validationHintField', [$t('name')]) }}
                    </p>

                    <p :class="{ active: !conditions.length }">
                        <CheckCircleIcon v-if="conditions.length" />
                        <RemoveCircleIcon v-else />
                        {{ $t('conditionPlaceholderHint') }}
                    </p>

                    <p :class="{ active: !actions.length && !webhooks.length }">
                        <CheckCircleIcon
                            v-if="actions.length || webhooks.length"
                        />
                        <RemoveCircleIcon v-else />
                        {{
                            isSuperuser
                                ? $t('actionAdminPlaceholderHint')
                                : $t('actionPlaceholderHint')
                        }}
                    </p>

                    <p :class="{ active: !isTargetSelected }">
                        <CheckCircleIcon v-if="isTargetSelected" />
                        <RemoveCircleIcon v-else />
                        {{ $t('targetPlaceholderHint', [$t('shared.assets')]) }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { ToggleButton } from 'vue-js-toggle-button'
import VSpinner from 'vue-simple-spinner'

import { httpHelper } from '@/utils'
import { userService } from '@/service/user.service'
import BaseButton from '../../redesigned/BaseButton'
import BaseCard from '../../redesigned/BaseCard'
import BaseHeader from '../../redesigned/BaseHeader'
import BaseInput from '../../redesigned/BaseInput'
import BaseMultiselect from '../../redesigned/BaseMultiselect'
import CheckCircleIcon from '../../icons/CheckCircleIcon'
import NotificationEditFragment from '../../redesigned/NotificationEditFragment'
import RemoveCircleIcon from '../../icons/RemoveCircleIcon'
import RichTextInput from '../../redesigned/RichTextInput'

export default {
    name: 'RuleEditView',
    components: {
        BaseButton,
        BaseCard,
        BaseHeader,
        BaseInput,
        BaseMultiselect,
        CheckCircleIcon,
        NotificationEditFragment,
        RemoveCircleIcon,
        RichTextInput,
        ToggleButton,
        VSpinner,
    },
    props: {
        template: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            actions: [],
            adminRule: false,
            conditions: [],
            currentId: null,
            delay: 7,
            delayIsCustom: false,
            isLoading: false,
            isTextExpanded: false,
            name: '',
            subject: '',
            owner: null,
            ownerIsCustom: false,
            spinnerColor: process.env.VUE_APP_COLOR_PRIMARY,
            targets: {},
            text: '',
            userEmail: '',
            userSms: '',
            webhooks: [],
            triggerMode: 'once',
            triggerModeOptions: ['always', 'once'],
            alertLevel: 'alarm',
            alertLevelOptions: ['alarm', 'none'],
        }
    },
    computed: {
        ...mapState('auth', ['userInfo']),
        ...mapGetters('auth', [
            'getCurrentUserId',
            'hasAdvancedRuleAccess',
            'hasWebhookAccess',
            'isSuperuser',
        ]),
        ...mapGetters('user', ['usersSortedByUsername']),
        isTargetSelected() {
            return (
                this.targets.assets?.type === 'all' ||
                this.targets.assets?.length ||
                this.targets.locations?.length ||
                this.targets.types?.length
            )
        },
        canSubmit() {
            return (
                (this.actions.length || this.webhooks.length) &&
                this.conditions.length &&
                this.name.length &&
                this.isTargetSelected
            )
        },
    },
    async mounted() {
        if (this.$el.parentElement) {
            this.$el.parentElement.scrollTop = 0
        }

        this.currentId = this.$router.currentRoute.params.id

        if (this.currentId) {
            try {
                this.isLoading = true
                const response = await httpHelper.get(
                    `/alertrules/${this.currentId}/`
                )
                this.prefill(response.data)
            } catch {
                this.currentId = null
                this.$router.push({ name: 'ruleCreate' })
                return
            } finally {
                this.isLoading = false
            }
        } else if (this.template) {
            this.$nextTick(() => {
                this.prefill(this.template)
            })
        }

        if (this.owner) {
            const {
                email_recipients,
                sms_recipients,
            } = await userService.fetchUserAlertSettings(this.owner.id)
            this.userEmail = email_recipients.join('; ')
            this.userSms = sms_recipients.join('; ')
        } else {
            const settings = this.userInfo?.alertsettings
            this.userEmail = settings?.email_recipients.join('; ')
            this.userSms = settings?.sms_recipients.join('; ')
        }
    },
    methods: {
        async handleSubmit() {
            const payload = {
                active: true,
                admin: this.adminRule,
                user: this.owner?.id || this.getCurrentUserId,
                delay: this.delayIsCustom ? this.delay : 0,
                name: this.name,
                subject: this.subject,
                text: this.text || null,
                alert_level: this.alertLevel,
                trigger: this.triggerMode,
                conditions: {
                    version: 1,
                    matchMode: 'all',
                    conditions: this.conditions.map(condition => {
                        switch (condition.value) {
                            case 'distance_to_service':
                                return {
                                    ...condition,
                                    threshold: condition.threshold * 1000,
                                }
                            case 'running_time':
                                return {
                                    ...condition,
                                    threshold: condition.threshold * 3600, // hours to seconds
                                }
                            case 'speed':
                                return {
                                    ...condition,
                                    threshold: condition.threshold / 3.6,
                                }
                            default:
                                return condition
                        }
                    }),
                },
                alert_by_email: this.actions.some(
                    item => item.type === 'EMAIL_PRIMARY'
                ),
                alert_by_sms: this.actions.some(
                    item => item.type === 'SMS_PRIMARY'
                ),
                notification_channels: this.actions.filter(item => item.target),
                all_assets: this.targets.assets?.type === 'all',
                assets: this.targets.assets || [],
                asset_types: this.targets.types || [],
                locations: this.targets.locations || [],
                webhooks: this.webhooks,
            }

            try {
                this.isLoading = true
                if (this.currentId) {
                    await httpHelper.put(
                        `/alertrules/${this.currentId}/`,
                        payload
                    )
                } else {
                    await httpHelper.post('/alertrules/', payload)
                }
                this.$router.push('/rules')
            } catch {
                this.isLoading = false
                this.$notify({
                    title: this.$t('submitFailedTitle'),
                    text: this.$t('submitFailedText'),
                })
            }
        },
        prefill(data) {
            this.adminRule = !!data.admin
            this.alertLevel = data.alert_level ?? 'alarm'
            this.conditions =
                data.conditions?.conditions.map(condition => {
                    switch (condition.value) {
                        case 'distance_to_service':
                            return {
                                ...condition,
                                threshold: condition.threshold / 1000,
                            }
                        case 'running_time':
                            return {
                                ...condition,
                                threshold: (condition.threshold / 3600).toFixed(
                                    2
                                ), // seconds to hours
                            }
                        case 'speed':
                            return {
                                ...condition,
                                threshold: Math.round(
                                    condition.threshold * 3.6
                                ),
                            }
                        default:
                            return condition
                    }
                }) ?? []
            this.delay = data.delay ?? 7
            this.delayIsCustom = data.delay > 0
            this.name = data.name ?? ''
            this.subject = data.subject ?? ''
            this.text = data.text ?? ''
            this.triggerMode = data.trigger ?? 'once'
            this.webhooks = data.webhooks ?? []

            if (data.user && data.user !== this.getCurrentUserId) {
                this.owner = this.usersSortedByUsername.find(
                    ({ id }) => id === data.user
                )
                this.ownerIsCustom = !!this.owner
            } else {
                this.owner = null
                this.ownerIsCustom = false
            }

            this.actions = data.notification_channels ?? []
            if (data.alert_by_sms) {
                this.actions.unshift({ type: 'SMS_PRIMARY' })
            }
            if (data.alert_by_email) {
                this.actions.unshift({ type: 'EMAIL_PRIMARY' })
            }

            this.targets = {}
            if (data.all_assets) {
                this.targets.assets = []
                this.targets.assets.type = 'all'
            }
            if (data.assets?.length) {
                this.targets.assets = data.assets
                this.targets.assets.type = 'assets'
            }
            if (data.locations?.length) {
                this.targets.locations = data.locations
                this.targets.locations.type = 'locations'
            }
            if (data.asset_types?.length) {
                this.targets.types = data.asset_types
                this.targets.types.type = 'types'
            }
        },
    },
}
</script>

<i18n>
{
    "en": {
        "action": "Then",
        "actionAdminPlaceholderHint": "Configure Notification or Webhook",
        "actionPlaceholder": "No Notification",
        "actionPlaceholderHint": "Configure Notification",
        "adminrule": "Admin Rule",
        "adminsettings": "Admin Settings",
        "advancedsettings": "Advanced Settings",
        "alarm": "Alarm",
        "alertLevel": "Alert level",
        "always": "Always",
        "condition": "If",
        "conditionPlaceholder": "No Condition",
        "conditionPlaceholderHint": "Configure Condition",
        "delay": "Delay",
        "name": "Name",
        "namePlaceholder": "Rule without Name",
        "none": "None",
        "once": "Once",
        "owner": "Assign",
        "ownerPlaceholder": "Select owner",
        "submitFailedTitle": "Error",
        "submitFailedText": "Failed to save the rule",
        "target": "Apply to",
        "targetPlaceholder": "No Application",
        "targetPlaceholderHint": "Choose {0} or Group",
        "text": "Text",
        "textPlaceholder": "Text (optional)",
        "time-h": "Hours",
        "titleCreate": "Create new Rule",
        "titleEdit": "Edit Rule",
        "triggerMode": "Trigger mode",
        "validationHintField": "Fill in the \"{0}\" field",
        "value": "Value",
        "webhooks": "Webhooks",
        "webhooksPlaceholder": "No Webhooks",
        "webhooksPlaceholderHint": "Configure Webhook",
        "subject": "E-mail subject",
        "subjectPlaceholder": "E-mail subject (optional)"
    },
    "de": {
        "action": "Dann",
        "actionAdminPlaceholderHint": "Benachrichtigung oder Webhook konfigurieren",
        "actionPlaceholder": "Keine Benachrichtigung",
        "actionPlaceholderHint": "Benachrichtigung einstellen",
        "adminrule": "Admin Regel",
        "adminsettings": "Admin Einstellungen",
        "advancedsettings": "Erweiterte Einstellungen",
        "alarm": "Alarm",
        "alertLevel": "Alarmstufe",
        "always": "Immer",
        "condition": "Wenn",
        "conditionPlaceholder": "Keine Bedingung",
        "conditionPlaceholderHint": "Bedingung einstellen",
        "delay": "Verzögerung",
        "name": "Name",
        "namePlaceholder": "Unbenannte Regel",
        "none": "Keine",
        "once": "Einmal",
        "owner": "Zuweisen",
        "ownerPlaceholder": "Benutzer",
        "submitFailedTitle": "Error",
        "submitFailedText": "Speichern der Regel ist fehlgeschlagen",
        "target": "Anwenden auf",
        "targetPlaceholder": "Keine Anwendung",
        "targetPlaceholderHint": "{0} oder Gruppen auswählen",
        "text": "Text",
        "textPlaceholder": "Text (optional)",
        "time-h": "Stunden",
        "titleCreate": "Neue Regel erstellen",
        "titleEdit": "Regel bearbeiten",
        "triggerMode": "Triggermodus",
        "validationHintField": "Feld \"{0}\" ausfüllen",
        "value": "Wert",
        "webhooks": "Webhooks",
        "webhooksPlaceholder": "Keine Webhooks",
        "webhooksPlaceholderHint": "Webhooks Konfigurieren",
        "subject": "E-Mail-Betreff",
        "subjectPlaceholder": "E-Mail-Betreff (optional)"
    },
    "fr": {
        "action": "Ensuite",
        "actionAdminPlaceholderHint": "Configurer les notifications ou le Webhook",
        "actionPlaceholder": "Pas de notification",
        "actionPlaceholderHint": "Configurer la notification",
        "adminrule": "Règle de l'administrateur",
        "adminsettings": "Paramètres de l'administrateur",
        "advancedsettings": "Paramètres avancés",
        "alarm": "Alarme",
        "alertLevel": "Niveau d'alarme",
        "always": "Toujours",
        "condition": "Simuler",
        "conditionPlaceholder": "Aucune condition",
        "conditionPlaceholderHint": "Configurer la condition",
        "delay": "Retardé",
        "name": "Nom",
        "namePlaceholder": "Règle sans nom",
        "none": "Aucune",
        "once": "Une fois",
        "owner": "Assigner",
        "ownerPlaceholder": "Selectionner",
        "submitFailedTitle": "Erreur",
        "submitFailedText": "La sauvegarde de la règle à échoué",
        "target": "Appliquer à",
        "targetPlaceholder": "Aucune application",
        "targetPlaceholderHint": "Choisir {0} ou grouper",
        "text": "Texte",
        "textPlaceholder": "Texte (optionnel)",
        "time-h": "Heures",
        "titleCreate": "Créer une nouvelle règle",
        "titleEdit": "Editer la règle",
        "triggerMode": "Mode d'activation",
        "validationHintField": "Remplir dans le \"{0}\" champ",
        "value": "Valeur",
        "webhooks": "Webhooks",
        "webhooksPlaceholder": "Aucun Webhook",
        "webhooksPlaceholderHint": "Configurer le Webhook",
        "subject": "Objet de l'e-mail",
        "subjectPlaceholder": "Objet de l'e-mail"
    },
    "it": {
        "action": "Poi",
        "actionAdminPlaceholderHint": "Configurare la notifica o il webhook",
        "actionPlaceholder": "Nessuna notifica", 
        "actionPlaceholderHint": "Impostare la notifica", 
        "adminrule": "Regola dell'Admin",
        "adminsettings": "Impostazioni amministratore",
        "advancedsettings": "Impostazioni avanzate",
        "alarm": "Allarme",
        "alertLevel": "Livello di allarme",
        "always": "Sempre",
        "condition": "Se",
        "conditionPlaceholder": "Nessuna condizione",
        "conditionPlaceholderHint": "Impostare la condizione", 
        "delay": "Ritardo",
        "name": "Nome",
        "namePlaceholder": "Regola senza nome",
        "none": "Nessuna",
        "once": "Una volta",
        "owner": "Assegnare",
        "ownerPlaceholder": "Selezionare",
        "submitFailedTitle": "Error",
        "submitFailedText": "Impossibile salvare la regola",
        "target": "Applicare a",
        "targetPlaceholder": "Nessuna applicazione",
        "targetPlaceholderHint": "Seleziona {0} o i gruppi",
        "text": "Text",
        "textPlaceholder": "Text (optional)",
        "time-h": "Ore",
        "titleCreate": "Creare una nuova regola",
        "titleEdit": "Modifica regola",
        "triggerMode": "Modalità di attivazione",
        "validationHintField": "Compilare il campo \"{0}\"",
        "value": "Valore",
        "webhooks": "Webhooks",
        "webhooksPlaceholder": "Nessun Webhooks",
        "webhooksPlaceholderHint": "Configura Webhook",
        "subject": "Oggetto dell'e-mail",
        "subjectPlaceholder": "Oggetto dell'e-mail (facoltativo)"
    }
}
</i18n>

<style lang="scss" scoped>
.rule-edit-view {
    &__container {
        padding: 3rem 1rem;

        & > * {
            margin-right: auto;
            margin-left: auto;
            width: 100%;
            max-width: 400px;
            transition: max-width 0.2s;

            &.large {
                max-width: 1200px;
            }
        }
    }

    &__admin-settings {
        div {
            height: 40px;
        }
    }

    &__validation-hints {
        padding: 0 4px;
        font-size: 14px;
        color: $color-primary;

        p {
            display: flex;
            align-items: center;
            opacity: 0.65;

            &.active {
                opacity: 1;
            }
        }

        svg {
            margin-right: 8px;
            opacity: 0.85;
        }
    }

    h2 {
        margin: auto;
        width: 100%;
        max-width: 400px;
        font-weight: 700;
        font-size: 20px;
        color: $color-text-new;
    }
}
</style>
