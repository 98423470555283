<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <path
            class="a"
            d="M12,16.5a.375.375,0,1,0,.375.375A.375.375,0,0,0,12,16.5h0"
        />

        <line class="b" x1="12" y1="13.5" x2="12" y2="6" />

        <path
            class="a"
            d="M1.28,13.281a1.812,1.812,0,0,1,0-2.561l9.44-9.439a1.808,1.808,0,0,1,2.56,0l9.44,9.439a1.812,1.812,0,0,1,0,2.561L13.28,22.72a1.811,1.811,0,0,1-2.56,0Z"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'AlertDiamondIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a,
.b {
    fill: none;
    stroke-linecap: round;
    stroke-width: 1.5px;
}

.a {
    stroke-linejoin: round;
}

.b {
    stroke-miterlimit: 10;
}
</style>
